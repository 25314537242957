@import url('theme.css');
@import 'theme.css';


html{
  height: 100%;
}

body{
  -webkit-user-select: none;
  -webkit-user-drag: none;
  -webkit-app-region: no-drag;
  cursor: default;
  color: var(--text-base);
  height: 100%;
}

::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px     rgba(0,0,0,0.3);  */
  border-radius: 20px;

}
::-webkit-scrollbar {
  width: 8px;
}

/* Add a thumb */
::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: var(--bg-hover) !important; ;

}
.scroll::-webkit-scrollbar-thumb{
  position: relative;
  right: -20px;
}
.dark::-webkit-scrollbar-thumb {
  background: #1f2022 !important ;
  border-radius: 20px;
}

.pubs.ant-collapse-item-active.ant-collapse-item{
  display: flex;
  flex-direction: column;
}
.pubs.ant-collapse-item-active .ant-collapse-content{
  flex: 1;
  display: flex;
  flex-direction: column;
}
.pubs.ant-collapse-item-active .ant-collapse-content-box{
  flex: 1;
}

#root, #root>div{
  height: 100%;
}
.shadow-teddra{
  box-shadow: rgba(0, 0, 0, 0.20) 0px 3px 12px
}
input[type="color"] {
	-webkit-appearance: none;

}
input[type="color"]::-webkit-color-swatch-wrapper {
	padding: 0;
}
input[type="color"]::-webkit-color-swatch {
	border: none;
}

/*TODO : ADD FILL ATRIBUTE TO TAILWIND CONFIG*/
.ant-tooltip{
  max-width: 100% !important;
}
.teddra-dark{
  fill: var(--text-base)
}
.networks .ant-tabs-nav-wrap{
  background: var(--bg-base-windows);
}
.networks .ant-tabs-nav-wrap{
  height: 40px ;
}
.networks .ant-tabs-nav-list{
  height: 100%;
}
.networks .ant-tabs{
  height: 100%;
}
.networks .ant-tabs-content-holder{
  border-left: 1px solid rgb(var(--border-base));
}
.hover\:outline-none:hover,
.focus\:outline-none:focus{
  outline: none !important;
}

.bg-sub-windows .ant-popover-arrow-content::before{
  background: #fff !important;
}
.ant-collapse,
.ant-collapse > .ant-collapse-item > .ant-collapse-header{
  color: var(--text-base) !important;
}
.ant-collapse-content{
  color: inherit !important;
}
.ant-popover-inner-content{
  background-color: var(--bg-base);
}
.locations-menu .ant-popover-inner-content{
  padding: 0 !important;
}
.ant-popover-arrow-content::before{
  background-color: #fff;
}
.ant-popover-inner,
.ant-popover-inner-content{
  border-radius: 12px;
}

#menubar,.menubar{
    -webkit-app-region: drag;
    
}
.ant-select-selector{
  width: 100%;
  height: 100% ;
}

.ant-input:not(.border),.focus\:outline-none:focus,.hover\:shadow-none:hover{
  outline: none;
  box-shadow: none !important;
  outline-offset: 0;
  outline-width: 0;
}
.ant-input:hover,
.ant-input:focus{
  border-color: inherit !important;
  outline: none;
  box-shadow: none !important;
  outline-offset: 0;
  outline-width: 0;
}
.active-mode,.active-mode-mini{
  position: relative;
}
.active-mode::after,
.active-mode-mini::after
{
  height: 3px;
  width: 100%;
  position: absolute;
  content: '';
  background-color: var(--bg-inverted);
  left: 0;
  bottom: -11px;
  border-radius: 4px;
}
.active-mode-mini::after{
  bottom: -14px !important;
}

body{
    background-color: transparent;
    overflow: hidden;
}
.input-h-standard{
  height: 35px;
}
.no-dragable{
    -webkit-app-region: no-drag
}
.textarea-ellipsis{
  -webkit-line-clamp: 2;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
}
.ellipsis-desc{
  display: -webkit-box;
-webkit-line-clamp: 3;
-webkit-box-orient: vertical;
overflow: hidden;
}
.ellipsis-1{
  display: -webkit-box;
-webkit-line-clamp: 1;
-webkit-box-orient: vertical;
overflow: hidden;
}
.ellipsis-6{
  display: -webkit-box;
-webkit-line-clamp: 6;
-webkit-box-orient: vertical;
overflow: hidden;
}
.ellipsis-5{
  display: -webkit-box;
-webkit-line-clamp: 5;
-webkit-box-orient: vertical;
overflow: hidden;
}
.ellipsis-4{
  display: -webkit-box;
-webkit-line-clamp: 4;
-webkit-box-orient: vertical;
overflow: hidden;
}
.ellipsis-3{
  display: -webkit-box;
-webkit-line-clamp: 3;
-webkit-box-orient: vertical;
overflow: hidden;
}
.ellipsis-2{
  display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
overflow: hidden;
}
.ellipsis-10{
  display: -webkit-box;
-webkit-line-clamp: 10;
-webkit-box-orient: vertical;
overflow: hidden;
}
.ellipsis-8{
  display: -webkit-box;
-webkit-line-clamp: 8;
-webkit-box-orient: vertical;
overflow: hidden;
}
.ant-pagination{
  display: flex;
  align-items: center;
}
.ant-pagination-item{
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--font-size-sm);
  background-color: transparent;
  margin-right: 0;
  border: none;
  color: var(--text-base);
  min-width: 20px;

}

.ant-pagination-item-active a{
  color: var(--text-base);
  font-weight: 700;
}
.ant-pagination-item-link{
  background-color: transparent !important;
  border: none !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
}
/*****tabs***/
.publish .ant-tabs-top > .ant-tabs-nav::before{
  border-bottom: 1px solid #e2e1e1 !important;
}
.publish .ant-tabs-tab:hover{
  color: inherit !important;
}
.publish .ant-tabs-tab{
  border-bottom: 3px solid transparent;
  padding: 8px 0 !important;
  font-weight: 600 !important;
}
.publish .ant-tabs-nav{
  height: var(--icon-bar-height);
}
.publish .ant-tabs-tab-active{
  border-bottom: 3px solid var(--bg-inverted) !important;
}
.publish .ant-tabs-tab + .ant-tabs-tab{
  margin: 0 0 0 32px !important;
}
.ant-tabs{
  height: 100%;
}
.ant-tabs-content-holder{
  height: 100%;
  background: var(--bg-base-windows);
}
.icons-bar .ant-tabs-tab + .ant-tabs-tab{
  margin: 0 0 0 16px !important;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
  color: inherit !important;
}
.networks .ant-tabs-nav-wrap{
  padding: 0 !important;
  display: flex;
  justify-content:flex-end;
}
.networks .ant-tabs-tab{
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 6px 0 !important;
  border-radius: 5px;
  color: var(--text-base);
  padding: 0 !important;
}
.networks .ant-tabs-tab:not(.ant-tabs-tab-active):hover{
  background: rgba(var(--bg-muted), 0.6);
}
.networks .ant-tabs-tab-active{
  background: rgba(0,0,0, 0.7);
  color: var(--text-inverted);
  fill: var(--text-inverted);
}
.networks .ant-tabs-tab-active svg{
  color: var(--text-inverted);
  fill: var(--text-inverted);

}
.networks .ant-tabs-nav-list{
  padding-top: 0 !important;
  padding: 16px 0px;
  align-items: center;
  flex: 0 !important;
}
.networks .ant-tabs-tabpane{
  padding: 0 !important;
}
.networks .show-icon{
  position: relative
}
.networks .icon{
  display: none;
}
.networks .show-icon:hover .icon{
  display: block;
}
.bg-sub-windows.ant-tree{
  background-color: transparent !important;
}

.ant-tabs-nav{
    margin-bottom: 0 !important;
}
.ant-tabs-nav-wrap{
    padding-left: 10px;
}
.bar .ant-tabs-nav-wrap{
  height: var(--icon-bar-height) !important;
}
.sidebar .ant-tree-list-holder-inner{
  @apply py-5;
}
.ant-tree-list-scrollbar-thumb{
  background: var(--bg-hover) !important;
}
.ant-tree-list-scrollbar.ant-tree-list-scrollbar-show{
  display: block !important;
  right: -1px !important;
}
.ant-tree-indent-unit{
  width: 31px !important;
}
.browser-tree .ant-tree-indent-unit{
  width: 24px !important;
}
.ant-tree-node-content-wrapper{
    display: flex;
    align-items: center;
    border-radius: 0.25rem 0 0 0.25rem !important;
  }
  .ant-tree-list .ant-tree-iconEle.ant-tree-icon__customize{
    display: flex !important
  }
  .ant-tree.ant-tree-list {
    @apply grid w-full;
  }
  .ant-tree .ant-tree-treenode,
  .ant-tree .ant-tree-list .ant-tree-node-content-wrapper{
    width: 100% !important;
  }
  .ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected,
  .ant-select-tree .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected{
    background-color: var(--bg-inverted) !important;
    color: var(--text-inverted) !important;
    border-radius: 0.25rem !important;
  }
  .ant-tree-treenode{
    padding-bottom: 0 !important;
  }
  .ant-tree-node-content-wrapper{
 
    @apply !px-1.5 !py-[1px] !transition-none border-[1px] border-transparent
  }
      .ant-tree.ant-tree-title {
        @apply whitespace-nowrap;
      }
    
      .ant-tree.ant-tree-treenode {
        @apply w-full p-0;
      }
    
      .ant-tree.ant-tree-node-content-wrapper {
        @apply w-full flex hover:bg-teddra-gray-100 hover:text-black rounded-none rounded-l py-1 pl-2 ;
  
    }
    .ant-tree .ant-tree-node-selected {
      @apply bg-teddra-red text-white;
    }

    .ant-tree .ant-tree-iconEle {
      @apply flex items-center w-max;
    }
  
      .ant-tree .ant-tree-switcher {
        @apply flex items-center justify-center;
      }
    
      .ant-tree .ant-tree-list .ant-tree-node-content-wrapper.ant-tree-node-selected svg {
        fill: #fff ;
   }
  
  
.ant-tree-treenode-selected img {
    filter: brightness(0) invert(1);
}
/* .modules .ant-tree-list-holder-inner .ant-tree-treenode:first-child .ant-tree-switcher{
  opacity: 0;
} */
.sidebar .ant-tree-list-holder {
    padding-top: 0;
    overflow-x: hidden;
    padding-right: 8px;
}
.sidebar .ant-tree-title {
    flex: 1;
}

/**spin**/
.ant-spin-nested-loading,.ant-spin-container{
    height: 100%;
}


.win-wrapper{
  @apply p-[12px] overflow-hidden;
}


.ant-tabs-ink-bar.ant-tabs-ink-bar-animated{
  display: none !important;
}

/***popover***/
.ant-popover{
  filter:drop-shadow(2px 2px 12px rgba(00,0,0,0.2))
}
.ant-popover-inner{
  border: 5px !important;
  box-shadow: none
}
.ant-popover-arrow{
  box-shadow: none !important;
}
.ant-tabs-top > .ant-tabs-nav::before, .ant-tabs-bottom > .ant-tabs-nav::before, .ant-tabs-top > div > .ant-tabs-nav::before, .ant-tabs-bottom > div > .ant-tabs-nav::before{
  border-color : rgb(236 236 236 / 0.3) !important;
}
.taskbar-popover .ant-popover-inner-content,
.popover-p-none .ant-popover-inner-content{
  padding: 0 !important;
}
.popover-arrow-none .ant-popover-arrow{
  display: none !important;
}
.taskbar-popover .ant-popover-arrow-content::before{
  background-color: #fff !important;
}


/***Treeselect********/
.ant-select{
  display: flex;
  align-items: center;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector{
  border: none !important;
  display: flex;
  align-items: center;
 }
 .ant-select-tree-list-holder::-webkit-scrollbar{
  width: 8px !important;
  height: 12px !important;
}
.filter-input .ant-select-selection-item{
  padding-right: 17px !important;
  padding-left: 0 !important;
}
.ant-select-selection-item{
  align-items: center;
  height: 100%;
}
.filter-input .ant-select-clear{
  margin-top: -9px !important;
}
.ant-select-tree-list-holder::-webkit-scrollbar{
  display: block;
}

.ant-select-tree-list-holder::-webkit-scrollbar-track{
  background: var(--bg-hover) !important;
  border-radius: 2px !important;
}

.ant-select-tree-list-holder::-webkit-scrollbar-thumb{
  border-radius: 10px !important;
  background-color: #CDCCCC !important; 
}
.ant-select-tree-node-content-wrapper.ant-select-tree-node-content-wrapper-normal.ant-select-tree-node-selected svg path{
  fill: #fff;
}
/* .ant-select-tree .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected{
  background-color: #E31E24 !important;
 } */
 .ant-select-tree .ant-select-tree-treenode{
  padding-bottom: 0 !important;
}
/* .ant-select-dropdown,
.ant-select-tree{
  background-color: var(--bg-base);
} */
.ant-input{
  background-color: #fff;
}
.where-list.bar{
  min-height: 800px;
 
}
.ant-select-dropdown:not(.ant-select-dropdown-hidden) .ant-select-tree-list-scrollbar{
  display: none !important;
}

.ant-select-tree-list-holder{
  overflow: auto !important;
}

.ant-select-tree-list-holder::-webkit-scrollbar{
  width: 8px !important;
  height: 12px !important;
}

.ant-select-tree-list-holder::-webkit-scrollbar{
  display: block;
}

.ant-select-tree-list-holder::-webkit-scrollbar-track{
  background-color: #F7F7F7 !important;
  border-radius: 2px !important;
}

.ant-select-tree-list-holder::-webkit-scrollbar-thumb{
  border-radius: 10px !important;
  background-color: #CDCCCC !important; 
}
.where-list .ant-select-tree-iconEle{
  width: max-content !important;
}
.where-list .ant-select-tree .ant-select-tree-switcher.ant-select-tree-switcher-noop{
  display: flex !important;
}
.where-list .ant-select-tree-title{
  padding-left: 10px ;
}
.img-white img,
.ant-select-tree-node-selected img{
filter: brightness(0) invert(1);
}
.what-list .ant-select-tree-title{
  padding-left: 10px;
}

  .ant-select-tree .ant-select-tree-title {
    @apply whitespace-nowrap;
  }
  .ant-select-tree .ant-select-tree-node-content-wrapper {
    @apply hover:bg-teddra-gray-100;

  }
  .ant-select-tree-node-content-wrapper .ant-select-tree-node-selected {
    @apply bg-teddra-red text-white;
  }
.ant-select-dropdown:not(.ant-select-dropdown-hidden) .ant-select-tree-list-scrollbar{
  display: none !important;
}

.ant-select-tree-list-holder{
  overflow: auto !important;
  /* @apply scrollbar-thin scrollbar-thumb-neutral-400 scrollbar-thumb-rounded-full; */
}
.pulications-dropdown.ant-btn-group > .ant-btn:first-child:not(:last-child){
  width: calc(100% - 30px);
  max-width: 100%;
}
.ant-tree-select-dropdown {
  @apply pr-0;
}

    .ant-tree-select-dropdown .ant-select-tree-node-content-wrapper {
      @apply flex rounded-none rounded-l py-1 pl-2 ; 

  }
  .ant-tree-select-dropdown .ant-select-tree-iconEle {
    @apply !flex items-center;

  }
  .ant-tree-select-dropdown .ant-select-tree-iconEle > svg {
    @apply mr-2;
  }

    .ant-tree-select-dropdown .ant-select-tree-switcher {
      @apply flex items-center justify-center;

  }
  .ant-tree-select-dropdown .ant-select-tree-switcher-noop {
    @apply hidden;
  }
 
 


 
.ant-select.resources-filter-tree-select .ant-select-selection-item {
@apply pl-0 text-sm truncate flex items-center;
}
/**********collapse*************/
.ant-collapse-header{
  padding-left: 0 !important;
}
.ant-collapse-header-text{
  padding-left: 15px !important;
}
.layout-panel .ant-collapse-header-text{
  padding-left: 13px !important;
}
.layout-panel .ant-collapse-header{
  margin-left: 14px !important;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  /* padding: 0 0 5px 0 !important; */
  font-weight: 700;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header, .ant-collapse-expand-icon, .ant-select-selection-placeholder {
  display: flex;
  align-items: center !important;
}


.publications-collapse {
  width: 100% !important;
}
.publications-collapse .ant-collapse-content-box {
  padding: 0 !important;
}
 .ant-collapse > .ant-collapse-item {
  border: none !important;
}
 .ant-collapse {
  width: 100% !important;
  background-color: inherit !important;
  padding-top: 3px !important;
}
 .ant-collapse-expand-icon {
  width: 15px;
}
 .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  margin-right: 0 !important;
}
 .ant-collapse-content-box{
  padding-right: 0 !important;
  padding-left: 6px !important;
}
/*************collapse***********/
.menu-publication .ant-btn-compact-first-item{
  flex: 1;
}
.menu-publication  .ant-btn-compact-last-item{
  width: 31px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.menu-publication .ant-btn-icon-only{
  padding: 1px 0 !important;
}
.menu-publication .ant-btn{
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
border: none !important;
box-shadow: none !important;
}
.ant-btn:hover, .ant-btn:focus{
border-color: transparent !important;
box-shadow: none !important;
}
[ant-click-animating-without-extra-node='true']::after{display:none !important;}
.ant-btn:hover, .ant-btn:focus{
  color: inherit !important;
}
.border-animated::after{
  content: '';
  height: 3px;
  width: 18px;
  background-color: rgb(var(--border-base));
  position: absolute;
  bottom: -1px;
}
.filter-input .ant-select-selection-placeholder,
.filter-input .ant-select-arrow{
  color: inherit !important;
}

/**viewer**/
.viewer .ant-tabs-tab{
  /* border-top: 3px solid transparent; */
  margin-left: 0px !important;
  color: inherit !important;
  border-left: 0 !important;
  background: rgba(var(--bg-muted), 0.6) ;
  border-top: 0 !important;
  border-bottom: 0 !important;
  font-size: var(--font-size-standard);
  border-radius: 0 0 5px 5px !important;
  position: relative;
}
.viewer .ant-tabs-tab{
  overflow: visible !important;
  background: rgba(250, 250, 250,0.7) !important;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap{
  overflow: visible !important;
}
.viewer .ant-tabs-tab:last-child{
  overflow: visible !important;
  background: transparent !important;
}
.viewer .ant-tabs-tab-active::before{
  content: '';
  position: absolute;
  width: 100%;
  background-color: #fff;
  height: 3px;
  top: -1px;
  left: 0;
  z-index: 1000;
}
.viewer .ant-tabs-nav-wrap{
  height: var(--icon-bar-height) !important;
  background-color: var(--bg-base-windows);
}
.viewer .ant-tabs-tab-active{
  background-color: #fff !important;
}
.viewer .ant-tabs-nav-list{
  padding-bottom: 5px;
}
.viewer .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
  color: inherit !important;
}
.viewer .ant-tabs-nav{
  margin-top: 0 !important;
}
.viewer .ant-tabs-nav-wrap{
  padding-left: 15px !important;
}
 .ant-tabs-content,
 .ant-tabs-tabpane{
  height: 100%;
}

.viewer .ant-tabs-nav{
  border-top: 1px solid rgba(var(--border-base),0.6);
}
/****MUNU***/
.ant-dropdown-menu-submenu-popup{
  width: 240px !important;
}
.ant-dropdown-menu-submenu-title{
  display: flex;
  align-items: center;
}

.ant-dropdown-menu-submenu-selected .ant-dropdown-menu-submenu-arrow-icon{
  fill: var(--text-inverted);
  color: var(--text-inverted) !important;
}
.ant-dropdown-menu-submenu-expand-icon{
  display: flex;
  align-items: center;
}
.ant-dropdown-menu-submenu-selected img,
.ant-dropdown-menu-item-selected img{
  filter: brightness(0) invert(1);
}
.ant-dropdown-menu-submenu-popup{
  margin-left: -9px;
}


.ant-drawer-body{
  padding: 0;
  overflow-x: hidden;
}
.ant-drawer-mask{
  background-color: transparent;
}
.ant-drawer-content{
  background-color: transparent;
  
}
.datafram .ant-drawer-top > .ant-drawer-content-wrapper{
  box-shadow: none;
}
.datafram .ant-scrolling-effect{
  width: 100% !important
}
.viewer-actions-drawer .ant-drawer-content-wrapper{
  height: calc(100vh - 141px) !important;
    top: 93px !important;
    right: 13px !important;
    box-shadow: none !important;
}
.unsplash-drawer .ant-drawer-content{
  border-radius: 10px;
}
.unsplash-drawer .ant-drawer-content-wrapper{
  height: calc(100vh - 126px) !important;
    top: 84px !important;
    left: 9px !important;
    box-shadow: none !important;
    box-shadow: 1px 1px 30px 1px rgba(161,161,161,0.15) !important;
    border-radius: 10px;
}
.unsplash-drawer.lg .ant-drawer-content-wrapper{
  height: calc(100vh - 165px) !important;

}
.color-picker .ant-popover-arrow{
  display: none;
}
.color-picker .ant-popover-inner-content{
  padding: 0 !important;
}

.ant-tabs-tab:hover{
  color: inherit !important;
}
.ant-tabs-nav{
  background-color: var(--bg-base-windows);
}
.create-desq .ant-tabs-nav-list{
  justify-content: space-between;
  width: 100%;
}
.create-desq .ant-tabs-nav-wrap{
  padding-left: 0 !important;
}
.create-desq.ant-tabs{
  height: 100%;
}
.create-desq .ant-tabs-tab{
  width: 140px;
  padding-bottom: 0 !important;
}
.create-desq-bg{
  background-color: #f2f2f2b3 !important;
}
.inner-p-none .ant-popover-inner-content{
  padding: 0 !important;
}
.ant-checkbox{
  padding: 2px !important;
  border: 1px solid var(--bg-inverted);
  border-radius: 3px;
}
.ant-checkbox-checked{
  border: 1px solid var(--bg-inverted);
}
.ant-checkbox-checked .ant-checkbox-inner{
  background-color: var(--bg-inverted);
 border-color: transparent !important;
}
.ant-checkbox .ant-checkbox-inner{
  width: 8px !important;
  height: 8px !important;
}
.ant-checkbox-checked .ant-checkbox-inner::after{
  display: none !important;
  
}

.ant-select-selector{
  box-shadow: none !important;
}
.popover-tab .ant-popover-content,
.popover-tab .ant-popover-arrow{
  left: 22px;
}
.popover-tab .ant-popover-arrow-content::before{
  background-color: #fff !important;
}
.upload-window .ant-select-selection-item{
  justify-content: start !important;
}
.upload-window .ant-select-clear{
  display: none;
}
.title-block1 {
  font-weight: 900;
  font-size: 40px;
  line-height: 42px;
}
.title-block2{
  font-size: 22px;
}
/* .react-joyride__tooltip button{
  background-color: #E31E24 !important;
} */


.ant-drawer-panel-motion-left-enter{
  transform: 0 !important;
  background-color: #000;
}


.expand-icon-hidden .ant-tree-switcher{
  display: none;
}
.ant-pagination-prev, .ant-pagination-next{
  margin: 0 !important;
  width: 14px !important;
  height: 14px !important;

  display: flex;
  align-items: center;
  justify-content: center;

}
.ant-modal-content{
  border-radius: 10px;
}
.ant-modal-mask{
  backdrop-filter: blur(8px) ;
  height: calc(100vh - 32px) !important;
  top: 16px !important;
  width: calc(100vw - 32px) !important;
  left: 16px !important;
}
.ant-popover-inner{
  background: transparent !important;
}
.server-minuature .ant-popover-inner-content{
  background-color: transparent;
  border-radius: 15px;
}
.server-minuature .ant-popover-content .ant-popover-inner{
  border-radius: 15px;
}
.server-minuature .ant-popover-arrow{
  width: 0 !important;
}

.panel-tree .ant-collapse-header,
.panel-tree .ant-collapse-content-box{
  padding: 0 !important;
  
}
.panel-tree .ant-collapse-header-text{
  padding: 0 !important;
  font-weight: normal;
}
.ant-dropdown-menu-submenu-title,
.ant-dropdown-menu-submenu-popup li{
  padding-right: 12px !important;
  color: var(--text-base) !important;
  fill: var(--text-base) !important;
}
.ant-dropdown-menu-submenu-disabled{
  opacity: 0.2 !important;
}
.ant-dropdown-menu{
  border-radius: 5px !important;
  background-color: var(--bg-menu) !important;
  color: var(--text-base) !important;
}
.ant-dropdown-menu-submenu-popup ul{
  padding: 0;
  margin: 0;
}
.ant-collapse-header{
  padding-top: 7px !important;
  padding-bottom: 4px !important;
}
.px-0 .ant-collapse-header{
  padding-left: 0 !important;
}

.map-popover .ant-popover-inner-content{
  border-radius: 11px !important;
  margin-left: -40px;
}
.map-popover .ant-popover-arrow{
  display: none !important;
}

.header-pl-0 .ant-collapse-header{
  padding-left: 0 !important;
}
.pt-0 .ant-collapse-header{
  padding-top: 0 !important;
}

.header-pl .ant-collapse-header-text{
  @apply !pl-1.5; 
}
.ant-upload-list{
  width: 100%;
  height: 100%;
  margin: 0 !important;
}
.ant-upload{
  border:1px solid rgba(var(--border-base),1) !important;
  background-color: #fff !important;
  border-radius:  0.25rem !important;
}
.ant-form-item-control-input-content,.ant-form-item-control-input,.ant-col{
  height: 100%;
}
.ant-form-item{
  margin-bottom: 0 !important;
}
.ant-select-tree .ant-select-tree-title{
  margin-left: 0.75rem !important;
}
.ant-select-tree-list-holder-inner{
  padding-left: 5px;
}
.mapboxgl-ctrl-attrib-inner,
.mapboxgl-ctrl-logo{
  display: none !important;
}
.ant-dropdown-menu-submenu-expand-icon svg{
  font-size: 7px;
}
.ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon svg{
  color: rgba(var(--text-muted))  !important;
  fill: rgba(var(--text-muted)) !important;

}
.ant-dropdown-menu-submenu-title:hover,
.ant-dropdown-menu-submenu-popup ul li:hover{
  background: var(--bg-hover) !important;
  border: 1px solid var(--border-hover)

  /* color: #fff !important; */
}
/* .ant-dropdown-menu-submenu-title:hover .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon svg{
  color: #fff !important;
  fill: #fff !important;

} */
.ant-spin-container::after{
  background: transparent !important;
  opacity: 1 !important;
}
.ant-dropdown-menu-item:hover{
  background: var(--bg-hover) ;
  border: 1px solid var(--border-hover)

}
.ant-dropdown-menu-item{
  color: var(--text-base) !important;
  fill: var(--text-base) !important;
  border: 1px solid transparent;
}
.ant-dropdown-menu-item{
  @apply rounded
}
.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu-selected,
.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu-selected .ant-dropdown-menu-submenu-title{
  background-color: var(--bg-inverted) !important;
  color: var(--text-inverted) !important;
  fill: var(--text-inverted) !important;

}
.blur-0 .ant-spin-blur{
  opacity: 0;
}
.blur-0 .ant-spin-nested-loading >div,.blur-0  .ant-spin{
  height: 100%;
  max-height: 100% !important;
}
.ant-upload.ant-upload-select-picture-card{
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
}
.h-full .ant-form-item-row{
  height: 100%;
}
.ant-tooltip-inner,.ant-tooltip-arrow-content::before{
  background: #fff !important;
  color: #000 !important;
}
.ant-tooltip-inner{
  padding: 20px 23px !important;
  border-radius: 10px !important;
}
.ant-drawer-left>.ant-drawer-content-wrapper{
  transform: translate(0) !important;
}